<template>
  <b-container fluid>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Team</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'name')" v-model="team.name" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Initials" :invalid-feedback="validationInvalidFeedback(errors.errors, 'initials')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'initials')" v-model="team.initials" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Mobile" :invalid-feedback="validationInvalidFeedback(errors.errors, 'mobile')">
                <b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'mobile')" v-model="team.mobile" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="9">
                    <b-icon class="align-self-center cursor-pointer text-muted" @click.prevent="toggleCollapse('team_members')" font-scale="1.5" :icon="collapsed['team_members'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Team Members</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-4" v-if="!collapsed['team_members']">
                  <b-col cols="12" v-if="!team.users.length" class="p-4">
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                          <p class="font-weight-normal h5 mb-0 text-dark">There are no users selected.</p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" v-if="team.users.length" class="p-4">
                    <user-search class="mb-3" @delete="rmUser(ind)" show-delete type="user" v-model="team.users[ind]" :key="ind" v-for="(user, ind) in team.users" />
                  </b-col>
                  <b-col cols="12">
                    <b-button block @click.prevent="addUser" variant="primary"><fa-icon icon="plus" /> Add User</b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import UserSearch from '../../components/UserSearch'

export default {
  mixins: [validation],
  components: { UserSearch },
  created () {
    if (this.$route.params.id) {
      this.fetch({ id: this.$route.params.id })
    }
  },
  computed: {
    ...mapGetters('teams', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  data () {
    return {
      collapsed: {
        team_members: false
      },
      team: {
        name: null,
        initials: null,
        mobile: null,
        users: []
      }
    }
  },
  methods: {

    ...mapActions('teams', ['fetch', 'save']),

    toggleCollapse (name) {
      this.collapsed[name] = !this.collapsed[name]
    },

    submit () {
      let data = {
        id: this.$route.params.id,
        name: this.team.name,
        initials: this.team.initials,
        mobile: this.team.mobile,
        users: this.team.users
      }

      this.save(data).then(response => {
        if([ 200, 201 ].includes(response.status)) this.$router.push({
          name: 'dash.teams.list',
          params: {
            notifications: [{
              variant: 'success',
              title: `Team #${response.data.id}`,
              text: `The team has been ${this.$route.params.id ? 'updated' : 'created'} successfully.`,
              timeout: 2500
            }]
          }
        })
      })
    },

    addUser() {
      this.team.users.push({
        id: null,
      });
    },
    rmUser(ind) {
      this.team.users.splice(ind, 1);
    },
  },
  watch: {
    single () {
      this.team = this.single
    }
  }
}
</script>

<style>

</style>
