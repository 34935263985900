<template>
  <router-view />
</template>

<script>
import teamModule from '../../store/teams'
import userModule from '../../store/users'

export default {
  beforeCreate () {
    this.$store.registerModule('teams', teamModule)
    this.$store.registerModule('users', userModule)
  },
  destroyed () {
    this.$store.unregisterModule('teams')
    this.$store.unregisterModule('users')
  }
}
</script>
